import {HttpClient} from '@angular/common/http';
import {Injectable, OnDestroy, inject} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {convertHTMLDateInputStringToISO8601} from '@util/functions/date';
import {getUrlOfEndpoint} from '@util/functions/strings';
import {BehaviorSubject, combineLatest, from, merge, Observable, of, Subject, Subscription, throwError} from 'rxjs';
import {catchError, filter, finalize, map, skip, switchMap, take, tap} from 'rxjs/operators';
import {KurzMasterRollCartEntry} from '../classes/kurz-master-roll-cart-entry';
import {buildFieldsValueFromObject} from '../custom-configuration-modules/custom-occ-endpoints-fields-value-function';
import {KurzPriceListPriceBaseType} from '../kurz-components/kurz-pricelist/kurz-pricelist.service';
import {KurzAddress, KurzAddressList, KurzConditionList, KurzShippingConditionList} from '../kurz-components/shared/types/kurz-adress.interface';
import {KurzCart, KurzCartEntry, KurzDetailedCart} from '../kurz-components/shared/types/kurz-cart.interface';
import {CuttingEntry} from '../kurz-components/shared/types/kurz-foil-configuration-list.interface';
import {KurzProduct} from '../kurz-components/shared/types/kurz-product.interface';
import {KurzToasterService} from './kurz-toaster.service';
import {KurzStock} from '../kurz-components/shared/kurz-stock-level/kurz-stock-level.types';
import {KurzOccCartNormalizer} from '../converter/kurz-occ-cart.normalizer';
import {transformCartCuttingEntriesToMasterRoll} from '../kurz-components/shared/kurz-configuration-display/kurz-configuration-display.functions';
import {ActiveCartService, CartActions} from '@spartacus/cart/base/core';
import {CartAddEntryFailEvent, CartAddEntrySuccessEvent, CartEvent, CartRemoveEntryFailEvent, CartRemoveEntrySuccessEvent, CartUpdateEntryFailEvent, CartUpdateEntrySuccessEvent} from '@spartacus/cart/base/root';
import {AuthService, ConfigurationService, EventService, Occ} from '@spartacus/core';
import {Order} from '@spartacus/order/root';
import { CookieBannerAndGtmService } from './cookie-banner-and-gtm.service';
import { AsyncDataCache } from '@util/classes/async-data-cache.class';
import { getSyncValue } from '@util/functions/rxjs-custom.operators';

interface KurzLoadCartSuccessPayload {
  userId: string;
  cartId: string;
  extraData?: {
    active?: boolean;
  };
  cart: KurzCart;
}

type SilentGlobalDeliveryDateUpdate = {
  oldDate: string;
  newDate: string;
  successful: boolean;
};

export type SilentUserParamsUpdate = {
  oldDate: string;
  newDate: string;
  oldReference: string;
  newReference: string;
  successful: boolean;
};

export interface KurzAddToCartData {
  materialNumber: string;
  priceBaseType?: KurzPriceListPriceBaseType;
  /**
   * is required if priceBaseType === 'CONTRACT'
   */
  contractEntryId?: string;
  /**
   * is required if priceBaseType === 'CONTRACT'
   */
  contractId?: string;
  /**
   * is required if priceBaseType === 'PROJECT'
   */
  projectName?: string;
  length: number;
  width: number;
  lengthImp?: number;
  widthImp?: number;
  qty: number;
  minQty?: number;
  /**
   * core code
   */
  core: string;
  /**
   * finishingType code
   */
  finishing: string;
  lang?: string;
  cartId?: string;
  myReference?: string;
  myMatNo?: string;
  cuttingForms?: CuttingEntry[],
  //priceRow?: KurzPriceListRowPriceObject;
  stockLevel?: KurzStock;
}

export interface KurzEditUserParams {
  cartId?: string;
  /**
   * without guid, myReference and deliveryDate are used to edit the active cart (globally) and not a single entry (locally)
   */
  guid?: string;
  myReference?: string;
  myMaterialNo?: string;
  deliveryDate?: string;
  noPartialDelivery?: boolean;
  completeDelivery?: boolean;
}

export type KurzEditGlobalCartUserParams = Pick<KurzEditUserParams, 'cartId' | 'myReference' | 'deliveryDate' | 'completeDelivery'>;
export type KurzEditCartEntryUserParams = { guid: string; } & Pick<KurzEditUserParams, 'cartId' | 'guid' | 'myReference' | 'myMaterialNo' | 'deliveryDate' | 'noPartialDelivery' | 'completeDelivery'>;


export interface KurzCartModificationResponseData {
  statusCode: 'success' | 'unavailable'; // see CommerceCartModificationStatus in BE
  quantity?: number;
  quantityAdded?: number;
  entry?: KurzCartEntry;
}

export interface KurzRemoveEntryAfterSecondsData {
  clearIntervalFn: () => void;
  secondsLeft: number;
  /**
   * null until "secondsLeft" is down to 0
   */
  responseData?: Observable<KurzCartModificationResponseData>;
  deleteNowFn: () => void;
}

export interface CouponClaimResponse {
  cart: KurzDetailedCart;
  couponResponse: {
    success: boolean;
    message?: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class KurzCartService implements OnDestroy {

  private readonly httpClient = inject(HttpClient);
  private readonly activeCartService = inject(ActiveCartService);
  private readonly eventService = inject(EventService);
  private readonly router = inject(Router);
  private readonly configurationService = inject(ConfigurationService);
  private readonly kurzToasterService = inject(KurzToasterService);
  private readonly store = inject(Store);
  private readonly kurzOccCartNormalizer = inject(KurzOccCartNormalizer);
  private readonly cookieBannerAndGtmService = inject(CookieBannerAndGtmService);
  // private readonly authService = inject(AuthService);

  // private deliveryAddressesAsyncDataSync = new AsyncDataCache(
  //   '_deliveryAddresses',
  //   {
  //     dataSerializable: false,
  //     // eslint-disable-next-line no-magic-numbers
  //     maxAge: (1000 * 60 * 60 * 1),
  //     sources: [
  //       {
  //         key: 'deliveryAddresses',
  //         object: {
  //           source: () => (this.requestDeliveryAddresses().pipe(filter(data => !!data))),
  //           refreshWhen: this.authService.isUserLoggedIn().pipe(skip(1))
  //         }
  //       }
  //     ]
  //   }
  // );

  // private paymentAddressesAsyncDataSync = new AsyncDataCache(
  //   '_paymentAddresses',
  //   {
  //     dataSerializable: false,
  //     // eslint-disable-next-line no-magic-numbers
  //     maxAge: (1000 * 60 * 60 * 1),
  //     sources: [
  //       {
  //         key: 'paymentAddresses',
  //         object: {
  //           source: () => (this.requestPaymentAddresses().pipe(filter(data => !!data))),
  //           refreshWhen: this.authService.isUserLoggedIn().pipe(skip(1))
  //         }
  //       }
  //     ]
  //   }
  // );

  private _activeCartId = '';
  private getActiveSub: Subscription;
  private mergedRegistration: Subscription;
  private onPageSubscription: Subscription;

  private onCartPageBehaviorSubject = new BehaviorSubject<boolean>(false);
  private onCheckoutReviewOrderPageBehaviorSubject = new BehaviorSubject<boolean>(false);

  private reloadActionCounter = 0;

  /**
   * this variable acts as a mutable workaround in situations where you want to update the global
   * delivery date but not by updating the immutable cart object (silent update)
   */
  private silentGlobalDeliveryDate: string = '';
  private oldGlobalUserParams: KurzEditUserParams;
  private oldLocalUserParamsMap = new Map<string, KurzEditUserParams>();

  private _cartStabilityOptInBehaviorSubject = new BehaviorSubject<boolean>(true);

  get onCartPage$(): Observable<boolean> {
    return this.onCartPageBehaviorSubject.asObservable();
  }

  get onCartPage(): boolean {
    return this.onCartPageBehaviorSubject.value;
  }

  get onCheckoutReviewOrderPage$(): Observable<boolean> {
    return this.onCheckoutReviewOrderPageBehaviorSubject.asObservable();
  }

  get onCheckoutReviewOrderPage(): boolean {
    return this.onCheckoutReviewOrderPageBehaviorSubject.value;
  }

  get activeCartId(): string {
    return this._activeCartId;
  }


  constructor() {

    this.getActiveSub = this.activeCartService.getActive()
      .pipe(filter(_ => !!_ && Object.keys(_).length > 0))
      .subscribe(cart => {
        this._activeCartId = cart.code;
      });

    //#region - register to all dispatchable events, which manipulates the cart and reload it automatically.
    // - use a counter variable to reload only if no other async cart manipulation action is in progress
    // example: deletion of two cart entries -> 2 actions -> 2 x dispatching of CartRemoveEntrySuccessEvent
    // but only the 2nd time is the counter decreased to 2 and the fe will reload
    const obsArr: Observable<CartEvent>[] = [
      this.eventService.get(CartAddEntrySuccessEvent),
      this.eventService.get(CartRemoveEntrySuccessEvent),
      this.eventService.get(CartUpdateEntrySuccessEvent),
    ];

    this.mergedRegistration = merge(...obsArr).subscribe(event => {
      if (this.reloadActionCounter <= 0) {
        this.reloadActiveCart();
      }
    });
    //#endregion

    this.onPageSubscription = this.router.events.pipe(filter(re => re instanceof NavigationEnd)).subscribe((navEnd: NavigationEnd) => {

      let url = navEnd.url;
      const questionMark = url.indexOf('?');

      if (questionMark >= 0) {
        url = url.slice(0, questionMark);
      }

      const urlParts = url.split('/');
      const splitLength = this.configurationService?.config?.context?.urlParameters?.length || 0;
      const pageParts = urlParts.slice(splitLength);

      const onCartPage = pageParts.length && pageParts[0] === 'cart';
      const onCheckoutReviewOrderPage = pageParts.length === 2 && pageParts[0] === 'checkout' && pageParts[1] === 'review-order';

      this.onCartPageBehaviorSubject.next(onCartPage);
      this.onCheckoutReviewOrderPageBehaviorSubject.next(onCheckoutReviewOrderPage);
    });

  }

  ngOnDestroy(): void {
    this.getActiveSub?.unsubscribe();
    this.mergedRegistration?.unsubscribe();
    this.onPageSubscription?.unsubscribe();
  }

  isStable(): Observable<boolean> {
    // isStable() from Spartacus knows when the cart is stable or not when it comes to spartacus methods
    // _cartStabilityOptInBehaviorSubject knows when the cart is stable or not when it comes to Kurz specific methods like addEntry or update of the user params
    // -> combine both to get a new observable and only if both are true, the cart (the backend) is up to date
    const isStableObserver = combineLatest([this.activeCartService.isStable(), this._cartStabilityOptInBehaviorSubject.asObservable()]).pipe(map(([defaultStable, optInStable]) => (defaultStable && optInStable)));

    return isStableObserver;
  }

  claimCoupon(couponCode: string): Observable<CouponClaimResponse> {
    const endpoint = '/coupon/claim';
    const url = getUrlOfEndpoint(endpoint, { couponCode });
    const body = {
      couponCode: couponCode,
      orderCode: this._activeCartId,
    };


    return this.httpClient.get<CouponClaimResponse>(url);
  }

  /**
   * FrontEnd (spartacus logic) will send the necessary requests to receive the new cart
   */
  setCartIdForFrontEnd(id?: string) {
    this.store.dispatch(new CartActions.ClearCartState());
    if (id) {
      this.store.dispatch(new CartActions.SetActiveCartId(id));
    }
  }

  proceedCheckoutAllowed(): Observable<boolean> {
    return combineLatest([this.isStable(), this.getActiveCart()]).pipe(
      map(([isStable, cart]) => {

        // early out if cart not stable, cart is falsy or there are no entries
        if (!isStable || !cart || cart?.entries?.length === 0) {
          return false;
        }

        // return false if some entries have validation errors or no validation
        // reorderValidation.containingError will be false if the has been validated and has no errors
        if (cart?.entries?.some(e => e?.reorderValidation?.containingError || !e.reorderValidation)) {
          return false;
        }

        // return true if
        // case 1 - a global date is set (position dates do not matter)
        // Note:
        // the silent global delivery date has higher priority than cart.globalDeliveryDate
        // because it can also set in silentlyUpdateGlobalDeliveryDate() if request was successful
        if (this.silentGlobalDeliveryDate) {
          return true;
        }

        // case 2 - all position dates are active (global date does not matter)
        if (cart?.entries?.every(entry => !!entry.overrideGlobalDate)) {
          return true;
        }

        return false;
      }));
  }

  getValidatedCart(): Observable<KurzCart> {

    const endpoint = '/cart/validated';
    const fieldsValue = buildFieldsValueFromObject({
      preConfiguredSet: 'FULL',
      fields: [
        'kurzSurcharges',
        'kurzDiscounts'
      ]
    });

    const url = getUrlOfEndpoint(endpoint, { fields: fieldsValue });

    this.optInCartStabilityAndSetTo(false);

    return this.httpClient.get<Occ.Cart>(url).pipe(
      tap(validatedCart => {
        let normalizedCart: KurzCart = {};
        this.kurzOccCartNormalizer.convert(validatedCart, normalizedCart);
        normalizedCart = Object.assign(validatedCart, normalizedCart);
        this.updateCartServerless(normalizedCart, true);
        this.optInCartStabilityAndSetTo(true);
      }),
      switchMap(_ => this.getActiveCart()),
      // Filter for validated carts here, since this is the only thing we should be getting at this point
      filter(cart => cart.entries.some(e => !!e.reorderValidation))
    );
  }

  /**
   * this is the centralized way to receive the cart
   */
  getActiveCart(): Observable<KurzCart> {
    return (this.activeCartService.getActive() as Observable<KurzCart>).pipe(
      filter(_ => !!_ && Object.keys(_).length > 0),
      map(cart => transformCartCuttingEntriesToMasterRoll(cart)),
      tap(cart => {
        this.oldGlobalUserParams = {
          cartId: cart.code,
          deliveryDate: cart.globalDeliveryDate,
          myReference: cart.orderReference
        };

        // making sure that it has the same value
        this.silentGlobalDeliveryDate = cart.globalDeliveryDate;

        this.oldLocalUserParamsMap.clear();

        cart.entries?.forEach(entry => {
          const param: KurzEditUserParams = {
            cartId: cart.code,
            guid: entry.guid,
            deliveryDate: entry.namedDeliveryDate,
            myReference: entry.myReference,
            myMaterialNo: entry.myMaterialNumber,
            noPartialDelivery: !!entry.noPartialDelivery
          };
          this.oldLocalUserParamsMap.set(param.guid, param);
        });
      })
    );

  }

  public testIfCartExistOrCreateNew(): void {
    const endpoint = 'users/current/carts';
    const url = getUrlOfEndpoint(endpoint);

    this.httpClient.get<{ carts: (Occ.Cart)[]; }>(url)
      .pipe(
        catchError(_ => of({ carts: [] })),
        filter(res => res.carts.length === 0),
        switchMap(_ => this.createActiveCart().pipe(take(1))),
      )
      .subscribe(newCart => this.updateCartServerless(newCart));
  }

  addEntry(data: KurzAddToCartData, product: KurzProduct): Observable<KurzCartModificationResponseData> {
    return (this.activeCartService.getActive() as Observable<KurzCart>).pipe(
      filter(cart => (!!cart?.code)),
      take(1),
      switchMap(cart => {
        // this is one of the actions after that there will be the need to reload the cart
        this.increaseReloadCounter();

        const isCartIdKnownBeforeAdd = !!data?.cartId;

        const url = getUrlOfEndpoint('cart/add');
        data.cartId = data.cartId ?? cart.code;
        data.priceBaseType = data.priceBaseType || 'STANDARD';
        const subject = new Subject<KurzCartModificationResponseData>();

        this.optInCartStabilityAndSetTo(false);

        this.httpClient.post<KurzCartModificationResponseData>(url, data).pipe(
          tap(_ => this.decreaseReloadCounter())
        ).subscribe({
          next: res => {

            const completesAddRequestFn = (cartId: string) => {
              if (res?.statusCode === 'success') {
                const event = new CartAddEntrySuccessEvent();
                event.cartCode = cartId;
                event.productCode = data.materialNumber;
                event.quantity = data.qty;
                event.entry = { product };
                this.eventService.dispatch(event, CartAddEntrySuccessEvent);
              } else {
                this.kurzToasterService.toastMessage(`Response: "${res?.statusCode}"`, 'error');
                const event = new CartAddEntryFailEvent();
                event.cartCode = cartId;
                event.productCode = data.materialNumber;
                event.quantity = data.qty;
                this.eventService.dispatch(event, CartAddEntryFailEvent);
              }

              subject.next(res);
              subject.complete();
            };

            if (!isCartIdKnownBeforeAdd) {
              this.reloadActiveCart().whenNextCart$.pipe(take(1)).subscribe({
                next: cart => {
                  completesAddRequestFn(cart.code);
                }
              });
            } else {
              completesAddRequestFn(data.cartId);
            }

          },
          error: err => {
            subject.error(err);
            subject.complete();
          },
          complete: () => {
            this.optInCartStabilityAndSetTo(true);
          }
        });

        return subject.asObservable();

      })
    );
  }

  addEntries(addToCartDataForm: KurzAddToCartData[], product: KurzProduct): Observable<KurzCartModificationResponseData[]> {

    return (this.activeCartService.getActive() as Observable<KurzCart>).pipe(
      filter(cart => (!!cart?.code)),
      take(1),
      switchMap(cart => {

        // this is one of the actions after that there will be the need to reload the cart
        this.increaseReloadCounter();

        const data = addToCartDataForm[0];
        const generalCartId = data.cartId || cart.code;

        const url = getUrlOfEndpoint('cart/addAll');

        addToCartDataForm.forEach(entry => {
          entry.cartId ||= generalCartId || cart.code;
          entry.priceBaseType ||= 'STANDARD';
        });

        this.optInCartStabilityAndSetTo(false);

        return this.httpClient.post<KurzCartModificationResponseData[]>(url, addToCartDataForm).pipe(
          tap(res => {
            this.decreaseReloadCounter();

            const atLeastOneSuccess = res.some(s => s.statusCode === 'success');
            const atLeastOneFailed = res.some(s => s.statusCode !== 'success');

            if (atLeastOneSuccess) {
              // in actuality, this would be an event for a single entry to added successful to the cart
              // maybe there is a better one but this suffices
              const event = new CartAddEntrySuccessEvent();
              event.cartCode = generalCartId;
              event.productCode = data.materialNumber;
              event.quantity = data.qty;
              event.entry = { product };
              this.eventService.dispatch(event, CartAddEntrySuccessEvent);
            }

            if (atLeastOneFailed) {
              const n = res.length;
              const iFailed = res.filter(s => s.statusCode !== 'success').length;
              this.kurzToasterService.toastMessage(`While adding ${n} entries to the cart: ${iFailed} failed.`, 'error');
            }

          }),
          finalize(() => {
            this.optInCartStabilityAndSetTo(true);
          })
        );
      })
    );

  }

  removeEntries(entries: KurzCartEntry[], deleteOffset = 0, counterIncreasedFromOutside = false): Observable<KurzCartModificationResponseData> {

    // this is one of the actions that there will be the need to reload the cart
    if (!counterIncreasedFromOutside) {
      this.increaseReloadCounter();
    }

    const subject = new Subject<KurzCartModificationResponseData>();

    // spartacus-cds.js expects every product to have at least one category or there will be an error if CartRemoveEntrySuccessEvent is dispatched
    const product = Object.assign({}, entries[0]?.product, { categories: (entries[0]?.product.categories || [{ code: '', name: '' }]) });

    this.optInCartStabilityAndSetTo(false);

    setTimeout(() => {

      this.removeCartEntriesFromSpecificCart(entries).pipe(
        tap(_ => {
          this.decreaseReloadCounter();
        })
      ).subscribe({
        next: res => {
          if (res?.statusCode === 'success') {
            const event = new CartRemoveEntrySuccessEvent();
            event.cartId = this._activeCartId;
            event.entry = { product };
            this.eventService.dispatch(event, CartRemoveEntrySuccessEvent);
          } else {
            this.kurzToasterService.toastMessage(`Response: "${res?.statusCode}"`, 'error');
            const event = new CartRemoveEntryFailEvent();
            event.cartId = this._activeCartId;
            event.entry = { product };
            this.eventService.dispatch(event, CartRemoveEntryFailEvent);
          }
          subject.next(res);
          subject.complete();
        },
        error: err => {
          subject.error(err);
          subject.complete();
        },
        complete: () => {
          this.optInCartStabilityAndSetTo(true);
        }
      });

    }, deleteOffset);

    return subject.asObservable();

  }

  removeEntryAfterSeconds(i: number, entry: KurzCartEntry, deleteOffset = 0): Observable<KurzRemoveEntryAfterSecondsData> {

    // this is one of the actions that will be in need to reload the cart
    this.increaseReloadCounter();

    let countdown = i;

    // a function called from the outside to cancel the countdown / to stop the deletion
    const clearIntervalFn = () => {
      if (handler) {
        clearInterval(handler);
        handler = null;
        this.decreaseReloadCounter();
      }
    };

    const deleteNowFn = () => {
      // here we need to increase the counter because we use "clearIntervalFn()" from within but the function was not meant to be.
      // Here, we finished the countdown regularly and we increase the counter to counteract the "clearIntervalFn()"
      this.increaseReloadCounter();
      clearIntervalFn();
      const localRemoveResponse = this.removeEntries([entry], deleteOffset, true);
      behaviorSubject.next({ clearIntervalFn: null, deleteNowFn: null, secondsLeft: 0, responseData: localRemoveResponse });
      behaviorSubject.complete();
    };

    const behaviorSubject = new BehaviorSubject<KurzRemoveEntryAfterSecondsData>({
      clearIntervalFn,
      secondsLeft: countdown,
      responseData: null,
      deleteNowFn
    });

    let handler = setInterval(() => {
      countdown--;
      let localRemoveResponse: Observable<KurzCartModificationResponseData> = null;
      if (countdown <= 0) {
        // here we need to increase the counter because we use "clearIntervalFn()" from within but the function was not meant to be.
        // Here, we finished the countdown regularly and we increase the counter to counteract the "clearIntervalFn()"
        this.increaseReloadCounter();
        clearIntervalFn();
        localRemoveResponse = this.removeEntries([entry], deleteOffset, true);
      }
      behaviorSubject.next({ clearIntervalFn: (countdown > 0) ? clearIntervalFn : () => { }, deleteNowFn: (countdown > 0) ? deleteNowFn : () => { }, secondsLeft: countdown, responseData: localRemoveResponse });
      if (countdown <= 0) {
        behaviorSubject.complete();
      }
    }, 1000);

    return behaviorSubject.asObservable();

  }

  removeCartEntriesFromSpecificCart(entries: KurzCartEntry[], specificCartId?: string): Observable<KurzCartModificationResponseData> {

    const guids = entries.map(e => e.guid).join(',');

    const url = getUrlOfEndpoint('cart/deleteEntries', {
      guids: guids,
      cartId: specificCartId || this._activeCartId,
    });
    return this.httpClient.delete<KurzCartModificationResponseData>(url).pipe(
      tap(res => {
        if (res.statusCode === 'success') {
          // NOTE: "delete" endpoint response does not contain an entry
          const ecom = this.cookieBannerAndGtmService.getEcommerceObjectFromEntries(entries, 'remove_from_cart', 'unknown', 'cartEntry');
          this.cookieBannerAndGtmService.addEcommerceEvent('remove_from_cart', ecom);
        }
      })
    );
  }

  updateQuantity(guid: string, quantity: number, product: KurzProduct): Observable<KurzCartModificationResponseData> {

    // this is one of the actions after that there will be the need to reload the cart
    this.increaseReloadCounter();

    const oldCart = getSyncValue(this.getActiveCart());
    const oldEntrie = oldCart.entries.find(e => e.guid === guid);
    const oldEcom = this.cookieBannerAndGtmService.getEcommerceObjectFromEntries([oldEntrie], 'remove_from_cart', 'unknown', 'cartEntry');

    const url = getUrlOfEndpoint('/cart/update');
    const subject = new Subject<KurzCartModificationResponseData>();

    const body = {
      quantity,
      cartId: this._activeCartId,
      guid
    };

    // spartacus-cds.js expects every product to have at least one category or there will be an error if CartRemoveEntrySuccessEvent is dispatched

    this.optInCartStabilityAndSetTo(false);
    this.httpClient.post<KurzCartModificationResponseData>(url, body).pipe(
      tap(_ => {
        this.decreaseReloadCounter();
      })
    ).subscribe({
      next: res => {
        if (res?.statusCode === 'success') {
          const event = new CartUpdateEntrySuccessEvent();
          event.cartId = this._activeCartId;
          event.entry = { product };
          this.eventService.dispatch(event, CartUpdateEntrySuccessEvent);

          // after sending the CartUpdateEntrySuccessEvent, the FE requests the new cart
          // we are waiting for the next cart
          this.getActiveCart().pipe(take(1)).subscribe(newCart => {

            const newEntrie = newCart.entries.find(e => e.guid === guid);
            const newEcom = this.cookieBannerAndGtmService.getEcommerceObjectFromEntries([newEntrie], 'add_to_cart', 'unknown', 'cartEntry');

            const diffEvent = this.cookieBannerAndGtmService.getDiffEcommerceEventForQuantityUpdate(oldEcom, newEcom);

            this.cookieBannerAndGtmService.addEcommerceEvent(diffEvent.eventType, diffEvent.ecommerce);

          });

        } else {
          this.kurzToasterService.toastMessage(`Response: "${res}"`, 'error');
          const event = new CartUpdateEntryFailEvent();
          event.cartId = this._activeCartId;
          event.entry = { product };
          this.eventService.dispatch(event, CartUpdateEntryFailEvent);
        }
        subject.next(res);
        subject.complete();
      },
      error: err => {
        subject.error(err);
        subject.complete();
      },
      complete: () => {
        this.optInCartStabilityAndSetTo(true);
      }
    });

    return subject.asObservable();
  }

  /**
   * Updates the user given properties of the entries in a cart
   * @param params: An KurzEditUserParams interface with cartId as optional field.
   * If the cartId value is not given it will asume that the id of the current
   * active cart.
   * @returns an observable of a KurzCartEndpointResponseData
   */
  public updateUserParamsOfEntry(params: KurzEditCartEntryUserParams): Observable<KurzCartModificationResponseData> {
    if ('cartId' in params) {
      const url = getUrlOfEndpoint('/cart/editUserParams');
      return this.httpClient.post<KurzCartModificationResponseData>(url, params);
    } else {
      const realParams = Object.assign(params, { cartId: this._activeCartId });
      return this.updateUserParams(realParams);
    }
  }

  updateGlobalUserParams(params: KurzEditGlobalCartUserParams): Observable<KurzCartModificationResponseData> {

    const realParams = Object.assign(params, { cartId: this._activeCartId });
    return this.updateUserParams(realParams);
  }

  private updateUserParams(params: KurzEditUserParams): Observable<KurzCartModificationResponseData> {

    params.deliveryDate = convertHTMLDateInputStringToISO8601(params.deliveryDate, true, 'noon');
    // transform truthy/falsey values to absolute boolean values
    params.noPartialDelivery = !!params.noPartialDelivery;

    if (this.oldGlobalUserParams) {
      this.oldGlobalUserParams.noPartialDelivery = !!this.oldGlobalUserParams.noPartialDelivery;
    }

    // a var in which we define if the global delivery changed or not -> if yes, we need to request the cart from the backensd / no serverless update possible
    let globalDeliveryDateChanged = false;
    let oldParams: KurzEditUserParams = params.guid ? this.oldLocalUserParamsMap.get(params.guid) : (this.oldGlobalUserParams);

    // now we check if the given params are different from the old params and if an update is even needed
    // we check the keys in params and as soon as one key value is not the same as in the old param value, we stop
    // but we need to begin with the "deliveryDate" key because if this param has changed
    // then we would need to request a new cart object additionally

    const paramsKeys = Object.keys(params);
    const deliveryDateKeyIndex = paramsKeys.indexOf('deliveryDate');
    if (deliveryDateKeyIndex >= 0) {
      paramsKeys.splice(deliveryDateKeyIndex, 1);
      paramsKeys.splice(0, 0, 'deliveryDate');
    }

    const needUpdate = paramsKeys.some(key => {

      if (key === 'deliveryDate') {

        let oldDate = '';
        if (oldParams?.deliveryDate) {
          const convDate = new Date(oldParams.deliveryDate || 0);
          oldDate = convDate.toISOString();
        }

        globalDeliveryDateChanged = params[key] !== oldDate && !params.guid; // a change of the global user params do not deliver "guid"

        return params[key] !== oldDate;
      } else {
        return params[key] !== oldParams[key];
      }

    });

    const url = getUrlOfEndpoint('/cart/editUserParams');
    const subject = new BehaviorSubject<KurzCartModificationResponseData>(null);

    if (needUpdate) {

      this.optInCartStabilityAndSetTo(false);

      this.httpClient.post<KurzCartModificationResponseData>(url, params).subscribe({
        next: res => {

          if (res?.statusCode === 'success') {

            if (globalDeliveryDateChanged) {
              this.reloadActiveCart().whenNextCart$.pipe(take(1)).subscribe(_ => {
                subject.next(res);
                subject.complete();
              });
            } else {
              // the user data was updated in the backend, now make a update in the frontend

              let serverlessUpdateObject: Partial<KurzCart>;

              // do you want to update the local user params of a target entry or else the global user params of the cart
              if (params.guid) {

                let currentCart: KurzCart;

                // get the current cart
                this.getActiveCart().pipe(take(1)).subscribe(cart => currentCart = cart);

                // get the index of the target entry of the current cart
                const entryIndex = currentCart?.entries?.findIndex(e => e.guid === params.guid);

                // get the current entries (Object.assign is used so you can use Array.splice())
                const currentEntries = Object.assign([] as KurzCartEntry[], currentCart?.entries);

                // get the target entry and merge the values in it, which you want to update
                let updatedEntry = currentEntries[entryIndex];

                const mergeObj: Partial<KurzCartEntry> = {
                  myMaterialNumber: params.myMaterialNo || '',
                  myReference: params.myReference || '',
                  namedDeliveryDate: params.deliveryDate || '',
                  overrideGlobalDate: !!params.deliveryDate,
                  noPartialDelivery: params.noPartialDelivery
                };

                if (updatedEntry instanceof KurzMasterRollCartEntry) {
                  updatedEntry = KurzMasterRollCartEntry.clone(updatedEntry, mergeObj);
                } else {
                  updatedEntry = Object.assign({}, updatedEntry, mergeObj);
                }


                // replace the targeted entry with the updated entry
                currentEntries.splice(entryIndex, 1, updatedEntry);

                // create the serverlessUpdateObject
                serverlessUpdateObject = {
                  entries: currentEntries
                };

              } else {

                // create the serverlessUpdateObject
                serverlessUpdateObject = {
                  globalDeliveryDate: params.deliveryDate || '',
                  orderReference: params.myReference || ''
                };

              }

              this.updateCartServerless(serverlessUpdateObject);
              subject.next(res);
              subject.complete();
            }

          } else {
            this.kurzToasterService.toastMessage(`Response: "${res?.statusCode}"`, 'error');
          }

          subject.next(res);
          subject.complete();
        },
        error: err => {
          subject.error(err);
          subject.complete();
        },
        complete: () => {
          this.optInCartStabilityAndSetTo(true);
        }
      });

    } else {

      subject.next({ statusCode: 'success' });
      subject.complete();

    }

    return subject.pipe(filter(_ => !!_));
  }

  /**
 * Performs a silent update of the global reference by sending a request to edit user parameters.
 *
 * @param {string} newReference - The new reference value to be set.
 *
 * @returns {Observable<SilentUserParamsUpdate>} An observable emitting the result of the silent update operation.
 */
  public silentlyUpdateGlobalReference(newReference: string): Observable<SilentUserParamsUpdate> {
    let data = this.getSilentUserParamsUpdateData(newReference, 'reference');

    const url = getUrlOfEndpoint('/cart/editUserParams');

    const params: KurzEditUserParams = {
      cartId: this._activeCartId,
      myReference: data.newReference,
      deliveryDate: data.newDate,
    };

    this.optInCartStabilityAndSetTo(false);

    return this.httpClient.post<KurzCartModificationResponseData>(url, params)
      .pipe(
        finalize(() => this.optInCartStabilityAndSetTo(true)),
        catchError(err => this.handleGlobalUpdateParamsError(err)),
        map(response => this.validateGlobalParamsUpdateResponse(response, data))
      );
  }

  /**
 * Performs a silent update of the global delivery date by sending a request to edit user parameters.
 *
 * @param {string} newDate - The new delivery date value to be set.
 *
 * @returns {Observable<SilentUserParamsUpdate>} An observable emitting the result of the silent update operation.
 *
 * This method first prepares the necessary data for the update by obtaining information about the new date
 * and constructing the parameters for the HTTP request. It then checks whether the cart has separate delivery dates
 * and proceeds accordingly:
 * - If separate delivery dates are enabled, it displays a warning toast message and triggers a full
 *   update of user parameters {@link updateUserParams}.
 * - If not, it performs the silent update by sending the HTTP request and handling potential errors.
 *
 * The observable emits the result of the update, encapsulated in the {@link SilentUserParamsUpdate} type.
 */
  public silentlyUpdateGlobalDeliveryDate(newDate: string): Observable<SilentUserParamsUpdate> {
    let data = this.getSilentUserParamsUpdateData(newDate, 'date');

    const params: KurzEditUserParams = {
      cartId: this._activeCartId,
      myReference: data.newReference,
      deliveryDate: data.newDate,
    };

    this.optInCartStabilityAndSetTo(false);
    const url = getUrlOfEndpoint('/cart/editUserParams');
    const silentQuery = this.httpClient.post<KurzCartModificationResponseData>(url, params)
      .pipe(
        finalize(() => this.optInCartStabilityAndSetTo(true)),
        catchError(err => this.handleGlobalUpdateParamsError(err))
      );

    return this.hasSeparateDeliveryDates().pipe(
      take(1),
      switchMap(hasSeparateDates => {
        if (hasSeparateDates) {
          this.kurzToasterService.translatedToastMessage('cart.header.resetDatesWarning');
          return this.updateUserParams(params).pipe(map(response => this.validateGlobalParamsUpdateResponse(response, data, false)));
        }
        return silentQuery.pipe(map(response => this.validateGlobalParamsUpdateResponse(response, data)));
      }),

    );
  }

  /**
 * Generates data for a silent update of user parameters based on the provided value and update type.
 *
 * @param {string} value - The new value for the user parameter.
 * @param {'reference' | 'date'} updateType - The type of user parameter being updated, either 'reference' or 'date'.
 *
 * @returns {SilentUserParamsUpdate} An object containing data for the silent update operation.
 */
  private getSilentUserParamsUpdateData(value: string, updateType: 'reference' | 'date'): SilentUserParamsUpdate {
    return {
      oldDate: this.oldGlobalUserParams.deliveryDate,
      newDate: convertHTMLDateInputStringToISO8601(updateType === 'date' ? value : this.oldGlobalUserParams.deliveryDate, true, 'noon'),
      oldReference: this.oldGlobalUserParams.myReference,
      newReference: updateType === 'reference' ? value : this.oldGlobalUserParams.myReference,
      successful: false,
    };
  }

  /**
 * Validates the response from a global parameters update operation and updates the provided data accordingly.
 *
 * @param {KurzCartModificationResponseData} response - The response from the global parameters update operation.
 * @param {SilentUserParamsUpdate} data - The data to be updated based on the response.
 * @param {boolean} updateCart - a flag to trigger the serverles car update. it is needed, since the silent params updates that
 * dont call the {@link updateUserParams} function, do not update the cart params on the front and the view shows the previous value.
 *
 * @returns {SilentUserParamsUpdate} The updated data reflecting the success or failure of the update.
 */
  private validateGlobalParamsUpdateResponse(response: KurzCartModificationResponseData, data: SilentUserParamsUpdate, updateCart: boolean = true): SilentUserParamsUpdate {
    if (response.statusCode === 'success') {
      if (updateCart) {
        this.updateCartServerless({ globalDeliveryDate: data.newDate, orderReference: data.newReference });
      }
      data.successful = true;
      this.oldGlobalUserParams.deliveryDate = data.newDate;
      this.silentGlobalDeliveryDate = data.newDate;
      this.oldGlobalUserParams.myReference = data.newReference;
    }
    return data;
  }

  /**
 * Handles errors that occur during a global update of user parameters and logs the error.
 *
 * @param {Error} err - The error that occurred during the update.
 *
 * @returns {Observable<KurzCartModificationResponseData>} An observable emitting a default response in case of an error.
 */
  private handleGlobalUpdateParamsError(err: Error): Observable<KurzCartModificationResponseData> {
    console.error('silently updating date at user params failed', err);
    return of({ statusCode: 'unavailable' });
  }

  private optInCartStabilityAndSetTo(state: boolean) {
    this._cartStabilityOptInBehaviorSubject.next(state);
  }

  /**
   * reloads the cart into the Spartacus cart infrastructure
   * Note: remember if you want to load a trustedMergerCart into the Spartacus infrastructure and you want to nullify a certain attribute,
   * you need to actively nullify it in the trustedMergerCart because this object will be merged into the current cart
   */
  reloadActiveCart(trustedMergerCart?: KurzCart): { whenNextCart$: Observable<KurzCart>; } {
    let whenNextCart$: Observable<KurzCart>;

    if (trustedMergerCart) {
      this.updateCartServerless(trustedMergerCart);
      whenNextCart$ = this.getActiveCart().pipe(take(1)) as Observable<KurzCart>;
    } else {
      this.setCartIdForFrontEnd('');
      whenNextCart$ = new Observable(subscriber => {

        this.isStable().pipe(filter(_ => !!_), take(1)).subscribe(isStable => {

          this.getActiveCart().pipe(
            filter(cart => {
              return !!cart && !!Object.keys(cart).length;
            }),
            take(1)
          ).subscribe({
            next: cart => {
              subscriber.next(cart as KurzCart);
              subscriber.complete();
            },
            error: err => {
              subscriber.error(err);
              subscriber.complete();
            },
          });

        });

      });
    }

    return { whenNextCart$ };
  }

  goToCartPage(): Observable<boolean> {
    return from(this.router.navigateByUrl('/cart'));
  }

  goToCheckoutPage(): Observable<boolean> {
    return from(this.router.navigateByUrl('/checkout/review-order'));
  }

  /**
   * necessary if you want to use the Spartacus cart infrastructure but you do not want to request new data from the backend
   * Note: remember if you want to update and you want to nullify a certain attribute,
   * you need to actively nullify it in the mergeCart because this object will be merged into the current cart
   */
  updateCartServerless(mergeCart: Partial<KurzCart>, secureCart?: boolean) {

    let userId: string;
    let cartRef: KurzCart;

    this.activeCartService.getAssignedUser()?.pipe(filter(_ => !!_), take(1)).subscribe(user => userId = user.uid);
    if (!secureCart) {
      this.getActiveCart().pipe(filter(_ => !!_), take(1)).subscribe(cart => cartRef = cart);
    }
    cartRef = Object.assign({}, cartRef, mergeCart);

    const payload: KurzLoadCartSuccessPayload = {
      cart: cartRef,
      cartId: cartRef.code || this._activeCartId,
      userId
    };

    this.store.dispatch(new CartActions.LoadCartSuccess(payload as unknown as any));
    this.store.dispatch(new CartActions.SetActiveCartId(payload.cartId));
  }

  private increaseReloadCounter() {
    this.reloadActionCounter++;
  }

  private decreaseReloadCounter() {
    this.reloadActionCounter--;
    if (this.reloadActionCounter < 0) {
      this.reloadActionCounter = 0;
      console.warn('decreaseReloadCounter() was called once too often');
    }
  }

  public deleteSpecificCart(cartId: string): Observable<void> {
    const endpoint = `/users/current/carts/${cartId}`;
    const url = getUrlOfEndpoint(endpoint);

    return this.httpClient.delete<void>(url).pipe(take(1));
  }

  createActiveCart(): Observable<KurzCart> {
    const args = {
      fields: buildFieldsValueFromObject({ preConfiguredSet: 'FULL' }),
    };
    const endpoint = '/users/current/carts';
    const url = getUrlOfEndpoint(endpoint, args);

    return this.httpClient.post<Occ.Cart>(url, {}).pipe(
      take(1),
      map(rawCart => {
        let normalizedCart: KurzCart = {};
        this.kurzOccCartNormalizer.convert(rawCart, normalizedCart);
        normalizedCart = Object.assign(rawCart, normalizedCart);
        return normalizedCart;
      })
    );
  }

  /**
   * @deprecated use setDeliveryModeWithIncoterms() instead
   */
  setDeliveryMode(deliveryModeId: string, notReloading?: boolean): Observable<KurzCart> {
    const url = getUrlOfEndpoint(`/orgUsers/current/cart/${this._activeCartId}/delivery-mode/${deliveryModeId}`);
    return this.httpClient.put<string>(url, {}).pipe(
      switchMap(res => {
        if (notReloading) {
          return this.getActiveCart();
        } else {
          // the cart in the response does not convey the new deliveryAddress -> it cannot be trusted
          // therefore we completely request a new cart
          return this.reloadActiveCart()?.whenNextCart$;
        }
      })
    );
  }

  /**
   */
  setDeliveryModeWithIncoterms(deliveryModeCode: string, incoterm1Code: string, incoterm2Value: string, notReloading?: boolean): Observable<KurzCart> {
    const endpoint = `/orgUsers/current/cart/${this._activeCartId}/delivery-mode/${deliveryModeCode}/incoTerm1/${incoterm1Code}/incoTerm2/${incoterm2Value}`;
    const url = getUrlOfEndpoint(endpoint);
    return this.httpClient.put<string>(url, {}).pipe(
      switchMap(res => {
        if (notReloading) {
          return this.getActiveCart();
        } else {
          // the cart in the response does not convey the new deliveryAddress -> it cannot be trusted
          // therefore we completely request a new cart
          return this.reloadActiveCart()?.whenNextCart$;
        }
      })
    );
  }


  getDeliveryAddresses(): Observable<{ addresses: KurzAddress[]; }> {
    // return this.deliveryAddressesAsyncDataSync.getResponse();
    return this.requestDeliveryAddresses();
  }

  private requestDeliveryAddresses() {
    const args = {
      fields: buildFieldsValueFromObject({
        preConfiguredSet: 'FULL',
        // fields: []
      })
    };
    const endpoint = '/org-units/shipping-addresses';
    const url = getUrlOfEndpoint(endpoint, args);
    return this.httpClient.get<{ addresses: KurzAddress[]; }>(url);
  }

  createDeliveryAddress(address: KurzAddress): Observable<KurzAddress> {
    const url = getUrlOfEndpoint(`/users/current/addresses/${this._activeCartId}/delivery`);
    return this.httpClient.post<KurzAddress>(url, address);
  }

  setDeliveryAddress(addressId: string, notReloading?: boolean): Observable<KurzCart> {
    // const url = getUrlOfEndpoint(`/orgUsers/current/carts/${this.activeCartId}/addresses/delivery`, {addressId});
    const endpoint = `/orgUsers/current/cart/${this._activeCartId}/shipping-address/${addressId}`;
    const url = getUrlOfEndpoint(endpoint);

    return this.httpClient.put<KurzCart>(url, {}).pipe(
      switchMap(res => {
        if (notReloading) {
          return this.getActiveCart();
        } else {
          // the cart in the response does not convey the new deliveryAddress -> it cannot be trusted
          // therefore we completely request a new cart
          return this.reloadActiveCart()?.whenNextCart$;
        }
      })
    );
  }


  /**
   * get a KurzConditionList with all conditions regarding the shipping
   * NOTE: setting a condition is not needed AT THE MOMENT
   */
  getShippingConditions(): Observable<KurzShippingConditionList> {
    const endpoint = '/org-units/shipping-conditions';
    const url = getUrlOfEndpoint(endpoint);
    return this.httpClient.get<KurzShippingConditionList>(url);
  }


  /**
   * get a KurzConditionList with all conditions regarding the payment/billing
   * NOTE: Show all conditions.
   */
  getPaymentConditions(): Observable<KurzConditionList> {
    const endpoint = '/org-units/billing-conditions';
    const url = getUrlOfEndpoint(endpoint);
    return this.httpClient.get<KurzConditionList>(url);
  }

  getPaymentAddresses(): Observable<KurzAddressList> {
    // return this.paymentAddressesAsyncDataSync.getResponse();
    return this.requestPaymentAddresses();
  }

  private requestPaymentAddresses(): Observable<KurzAddressList> {
    const args = {
      fields: buildFieldsValueFromObject({
        preConfiguredSet: 'FULL',
        // fields: []
      })
    };
    const endpoint = '/org-units/billing-addresses';
    const url = getUrlOfEndpoint(endpoint, args);
    return this.httpClient.get<{ addresses: KurzAddress[]; }>(url);
  }

  setPaymentAddress(addressId: string, notReloading?: boolean): Observable<KurzCart> {
    // const endpoint = `/kurz-de/orgUsers/current/payment-address/${addressId}`;
    const endpoint = `/orgUsers/current/cart/${this._activeCartId}/payment-address/${addressId}`;
    const url = getUrlOfEndpoint(endpoint);

    return this.httpClient.put<{ response: string; }>(url, {}).pipe(
      switchMap(res => {
        if (notReloading) {
          return this.getActiveCart();
        } else {
          // the cart in the response does not convey the new deliveryAddress -> it cannot be trusted
          // therefore we completely request a new cart
          return this.reloadActiveCart()?.whenNextCart$;
        }
      })
    );
  }

  placeOrder(termsChecked: boolean): Observable<Order> {

    const args = {
      fields: 'FULL',
      cartId: this._activeCartId,
      termsChecked: termsChecked ? 'true' : 'false'
    };
    const endpoint = '/orgUsers/current/orders';
    const url = getUrlOfEndpoint(endpoint, args);

    return this.httpClient.post<Order>(url, {}).pipe(
      catchError((err, caught) => {
        this.router.navigateByUrl('/cart').then(navigated => {
          this.kurzToasterService.translatedToastMessage('checkout.reviewOrder.site.orderError', 'error');
        });
        return throwError(err).pipe(take(1));
      }),
      tap(order => {
        const query = order?.code ? ('?orderCode=' + order.code) : '';
        this.router.navigateByUrl('/order-confirmation' + query)
          .then(navigated => {
            this.setCartIdForFrontEnd('');
          });
      })
    );

  }

  saveAsFavourite(saveAs: string): Observable<{ value: string; }> {

    const args = {
      cartCode: this._activeCartId,
      savedCartName: saveAs
    };

    const endpoint = '/cart/savedCartFromCart';
    const url = getUrlOfEndpoint(endpoint, args);

    return this.httpClient.get<{ value: string; }>(url);

  }

  requestDetailedPriceCart(): Observable<KurzDetailedCart> {

    const endpoint = '/cart/priceRequest';
    const args = { cartId: this._activeCartId } as Record<string, string>;
    // args.priceRequestCode = this.createHashCodeOfCart();
    const url = getUrlOfEndpoint(endpoint, args);

    return this.httpClient.get<{ value: string; }>(url).pipe(
      catchError((err, caught) => {
        console.warn('requestDetailedPriceCart', err);
        return of({ value: 'OK' });
      }),
      switchMap(res => this.reloadActiveCart()?.whenNextCart$ as Observable<KurzDetailedCart>)
    );
  }

  /**
   * true if at least one cart entry has separate delivery date and would therefore override the global delivery date of the cart
   */
  hasSeparateDeliveryDates(): Observable<boolean> {
    return this.getActiveCart().pipe(
      map(cart => {
        if (cart) {
          return cart?.entries.some(entry => entry.overrideGlobalDate);
        } else {
          return false;
        }
      })
    );
  }

}
