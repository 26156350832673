import {KurzMasterRollCartEntry} from 'src/app/custom/classes/kurz-master-roll-cart-entry';
import {KurzContractEntry} from '../../kurz-contracts/kurz-contracts.service';
import {KurzCart, KurzCartEntry} from '../types/kurz-cart.interface';
import {KurzConfigurationDisplayData} from '../types/kurz-foil-configuration-list.interface';
import {groupBy} from '@util/functions/objects';
import {KurzReorderResponseData} from '../../kurz-reorder/kurz-reorder.types';


export function transformKurzEntryToConfigurationDisplayData(entry: KurzCartEntry): KurzConfigurationDisplayData {

  const hasMasterRollObject = !!(entry.masterRoll);
  const isKurzMasterRollCartEntry = entry instanceof KurzMasterRollCartEntry;
  let isMasterRoll = false;
  let isCutSteps = false;
  let cuttingEntries: KurzMasterRollCartEntry['entries'] = [];
  if (hasMasterRollObject && isKurzMasterRollCartEntry) {
    isMasterRoll = !entry.isCutSteps;
    isCutSteps = entry.isCutSteps;
    cuttingEntries = entry.entries;
  }

  return {
    configType: entry?.product?.productDisplayType === 'graphics' ? 'GRAPHICS' : 'PLASTICS',
    core: {
      code: entry?.coreSpecification?.code,
      name: entry?.coreSpecification?.name
    },
    finishingType: entry?.finishingType,
    length: entry?.foilLength,
    width: entry?.foilWidth,
    minimumQuantity: entry?.minimumQuantity,

    priceBaseType: entry?.priceBaseInfo?.priceBaseType,

    unit: entry?.priceBaseInfo?.unit,
    unitFactor: entry?.priceBaseInfo?.unitFactor ?? 1,

    scaleUnit: entry?.priceBaseInfo?.scaleUnit,
    scaleUnitFactor: entry?.priceBaseInfo?.scaleFactor,

    contractName: entry?.priceBaseAsOrdered,

    price: entry?.priceBaseInfo?.amount,
    currencyIso: entry?.priceBaseInfo?.currency?.isocode,

    isMasterRoll,
    isCutSteps,
    cuttingEntries
  };

}

export function transformKurzContractEntryToConfigurationDisplayData(entry: KurzContractEntry): KurzConfigurationDisplayData {

  let foundProductType = entry?.product?.productDisplayType;

  if (!foundProductType) {
    foundProductType = entry?.product?.productDisplayType;
  }

  return {
    configType: foundProductType === 'graphics' ? 'GRAPHICS' : 'PLASTICS',
    core: {
      code: entry?.foilConfiguration?.core?.code,
      name: entry?.foilConfiguration?.core?.name
    },
    finishingType: entry?.foilConfiguration?.finishingType,
    length: entry?.foilConfiguration?.length,
    width: entry?.foilConfiguration?.width,
    priceBaseType: entry?.price?.priceType,
    unit: entry?.price?.unit,
    unitFactor: entry?.price?.unitFactor ?? 1,

    hidePriceBase: true,

    contractName: entry?.price?.contractName,
    currencyIso: entry?.price?.currencyData?.isocode
  };

}

export function transformCartCuttingEntriesToMasterRoll(cart: KurzCart): KurzCart {
  let entries = transformCuttingEntryToMasterRoll([...cart.entries]);
  const totalUnitCount = entries?.reduce((total, e) => total + e.quantity, 0) || 0;
  if (entries.some(e => e instanceof KurzMasterRollCartEntry)) {
    cart = Object.assign({}, cart, {
      updatedMasterRoll: true,
      entries,
      totalItems: entries?.length || 0,
      totalUnitCount
    });
  }
  return cart;
}

export function transformReorderCuttingEntriesToMasterRoll(response: KurzReorderResponseData): KurzReorderResponseData {
  response.entries = transformCuttingEntryToMasterRoll([...response.entries]);
  return response;
}

function transformCuttingEntryToMasterRoll(entries: KurzCartEntry[]): KurzCartEntry[] {
  // if there is no KurzMasterRollCartEntry already in entries => making sure that is a untouched cart object from BE
  if (!entries.some(e => e instanceof KurzMasterRollCartEntry)) {
    // get all entries with a master roll reference
    let masterRolls = entries.filter(entry => !!entry.masterRoll?.reference);
    if (masterRolls) {
      let masterRollGroups = groupBy(masterRolls, e => e.masterRoll?.reference);
      Object.keys(masterRollGroups).forEach(mrReference => {
        let cuttings = masterRollGroups[mrReference].sort(cutting => cutting.masterRoll.remainder ? 1 : -1);
        let masterRoll: KurzMasterRollCartEntry = new KurzMasterRollCartEntry(cuttings);

        // get the position, where the first masterroll entry object is
        const index = entries.findIndex(e => e.masterRoll?.reference === mrReference);

        // remove all cuttings from the entries array, which are used to create the master roll instance
        entries = entries.filter(entry => !cuttings.includes(entry));
        // add the instance at the position where the first masterroll entry object used to be
        entries.splice(index, 0, masterRoll);

      });

    }
  }
  return entries;
}
