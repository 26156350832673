import {KurzCartEntry} from '../kurz-components/shared/types/kurz-cart.interface';
import {KurzProduct} from '../kurz-components/shared/types/kurz-product.interface';


export class KurzMasterRollCartEntry implements KurzCartEntry {

  static clone(target: KurzMasterRollCartEntry, mergeObject: Partial<KurzCartEntry> = {}): KurzMasterRollCartEntry {

    const clonedEntryArray: KurzCartEntry[] = [];

    target.entries?.forEach(entry => {
      clonedEntryArray.push(Object.assign({}, entry, mergeObject));
    });

    const clonedTarget = new KurzMasterRollCartEntry(clonedEntryArray);

    return clonedTarget;
  }

  private _entries: KurzCartEntry[];

  get guid(): string {
    return this.entries[0].guid;
  }

  get isCutSteps(): boolean {
    return this.entries[0].isCutSteps;
  }

  get reference(): string {
    return this.entries[0].masterRoll.reference;
  }

  get quantity(): number {
    return this.entries[0].masterRoll.quantity;
  }

  get product(): KurzProduct {
    return this.entries[0]?.product;
  }

  set product(value: KurzProduct) {
    this.entries.forEach(e => e.product = value);
  }

  get coreSpecification(): KurzCartEntry['coreSpecification'] {
    return this.entries[0].coreSpecification;
  }

  get foilLength(): number {
    return this.entries[0].foilLength;
  }

  get foilWidth(): number {
    return (this.entries.reduce((width, e) => width + e.foilWidth * e.quantity, 0)) / this.quantity;
  }

  get finishingType(): KurzCartEntry['finishingType'] {
    return this._entries[0].finishingType;
  }

  get foilArea(): number {
    return this.entries.reduce((area, e) => area + e.foilArea, 0);
  }

  get priceBaseInfo(): KurzCartEntry['priceBaseInfo'] {
    return this.entries[0].priceBaseInfo;
  }

  get totalPrice(): KurzCartEntry['totalPrice'] {
    return {
      value: this.entries[0].masterRoll.price,
      currencyIso: this.entries[0].totalPrice.currencyIso,
    };
  }

  //#region - needed for position based user params
  get myMaterialNumber(): string {
    return this.entries[0].myMaterialNumber;
  }

  get myReference(): string {
    return this.entries[0].myReference;
  }

  get namedDeliveryDate(): string {
    return this.entries[0].namedDeliveryDate;
  }

  get overrideGlobalDate(): boolean {
    return this.entries[0].overrideGlobalDate;
  }

  get noPartialDelivery(): boolean {
    return this.entries[0].noPartialDelivery;
  }
  //#endregion

  get entries(): KurzCartEntry[] {
    return this._entries;
  }

  get masterRoll(): KurzCartEntry['masterRoll'] {
    return this._entries?.[0].masterRoll;
  }

  get reorderValidation(): KurzCartEntry['reorderValidation'] {
    return this._entries[0].reorderValidation;
  }

  get priceBaseAsOrdered(): string {
    return this._entries[0].priceBaseAsOrdered;
  }

  set entries(values: KurzCartEntry[]) {
    this._entries = [];
    values.forEach(v => this._entries.push(Object.assign({}, v)));
  }

  constructor(entries: KurzCartEntry[]) {
    this.entries = entries;
  }
}
