import { Injectable } from '@angular/core';
import {
  Converter,
  ConverterService,
  Occ
} from '@spartacus/core';
import { KurzCart } from '../kurz-components/shared/types/kurz-cart.interface';
import { OccCartNormalizer } from '@spartacus/cart/base/occ';

@Injectable({ providedIn: 'root' })
export class KurzOccCartNormalizer extends OccCartNormalizer implements Converter<Occ.Cart, KurzCart> {

  constructor(converter: ConverterService) {
    super(converter);
  }

  override convert(source: Occ.Cart, target?: KurzCart): KurzCart {
    return super.convert(source, target) as KurzCart;
  }

}


export class KurzOccCartTestingNormalizer {

  convert(source: Occ.Cart, target?: KurzCart): KurzCart {
    return target;
  }

}
